
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  background-color: lighten($color: $default, $amount: 15%);
}

.item-enter-done {
  background-color: $default;
  transition: background-color 1s ease-out 1s;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.cursor {
  cursor: pointer;
}

.stock-red {
  color: $stock-red;
}

.stock-green {
  color: $stock-green;
}

.easyoptions-green {
  color: $easyoptions-green;
}

.header-card {
  height: 130px;
}